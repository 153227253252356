import { fetch } from '@/utils/fetch';

interface WebsiteData {
  id: string;
  type?: string;
  fbPageAccessToken?: string;
  wavyAccessToken?: string;
  language?: string;
  lang?: string;
  labs: {
    [key: string]: string;
  };
}

interface DataResponse {
  data: {
    results: WebsiteData[];
  };
}

export interface AppWebsiteData {
  websiteId: string;
  editorType: 'facebook' | 'whatsapp' | 'instagram' | 'website';
  lang: string;
  labs: {
    [key: string]: string;
  };
  websites: JsonObject[];
}

export async function websiteDataAdapter() {
  try {
    const { data: websiteData } = (await fetch(
      '/sites'
    )) as unknown as DataResponse;
    const websites = websiteData.results;
    return {
      websites,
    };
  } catch (error) {
    throw new Error('Failed to fetch website data');
  }
}

// function getEditorType(
//   currentWebsite: WebsiteData
// ): AppWebsiteData['editorType'] {
//   const { wavyAccessToken, type } = currentWebsite;
//   if (wavyAccessToken) return 'whatsapp';
//   if (type === 'FACEBOOK') return 'facebook';
//   if (type === 'INSTAGRAM') return 'instagram';
//   return 'website';
// }
