import { accountDataAdapter } from './adapters/accounDataAdapter';
import { userDataAdapter } from './adapters/userDataAdapter';
import { websiteDataAdapter } from './adapters/websiteDataAdapter';
import { featuresDataAdapter } from './adapters/featuresDataAdapter';

export async function bootstrapAppData() {
  const user = await userDataAdapter();
  const account = await accountDataAdapter();
  const { websites } = await websiteDataAdapter();
  const features = await featuresDataAdapter(account.accountId);
  return {
    user,
    account,
    websites,
    features,
  };
}
