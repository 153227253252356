import { APP_ENV_MODE } from './environment';

const URLS_SET = {
  development: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'localhost:3030/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
    ECOM_FULFILLMENT: 'https://ecom.stagecliengo.com/fulfillment',
  },
  stage: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'https://live.stagecliengo.com/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
    ECOM_FULFILLMENT: 'https://ecom.stagecliengo.com/fulfillment',
  },
  production: {
    LOGIN: 'https://www.login.cliengo.com',
    API_URL: 'https://api.cliengo.com/1.0',
    CORE_APP_URL: 'https://app.cliengo.com',
    WEBOPTIMIZER_URL: 'https://s.cliengo.com/weboptimizer',
    SOCKETEER: 'wss://socketeer.cliengo.com/operators',
    LIVE: 'https://live.cliengo.com/live',
    CRM: 'https://crm.cliengo.com',
    LW: 'https://lw.cliengo.com',
    ECOM_FULFILLMENT: 'https://ecom.cliengo.com/fulfillment',
  },
};

export const URLS = URLS_SET[APP_ENV_MODE];
