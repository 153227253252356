import axios from 'axios';

import { URLS } from '@/constants/urls';

export interface FeatureData {
  allConvexConfigs2: boolean;
}

export async function featuresDataAdapter(
  accountId: string
): Promise<FeatureData> {
  try {
    const response = await axios.post(
      `${URLS.ECOM_FULFILLMENT}/launchdarkly/has-value-in-clause`,
      {
        ruleDescription: 'Por company ID',
        featureFlagKey: 'features.core.allConvexConfigs2.ecom-1221',
        clauseCondition: 'companyId',
        companyId: accountId,
      }
    );
    const allConvexConfigs2 = response.data as unknown as boolean;
    return {
      allConvexConfigs2,
    };
  } catch (error) {
    throw new Error('Failed to fetch account data');
  }
}
