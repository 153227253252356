import { fetch } from '@/utils/fetch';

interface AccountData {
  id: string;
  lang?: string;
  language?: string;
  planName: string;
  name: string;
  labs: {
    [key: string]: string;
  };
}

interface DataResponse {
  data: AccountData;
}

export interface AppAccountData {
  accountId: string;
  lang: string | undefined;
  planName: string;
  name: string;
  labs: {
    [key: string]: string;
  };
}

export async function accountDataAdapter(): Promise<AppAccountData> {
  try {
    const { data: accountData } = (await fetch(
      '/account'
    )) as unknown as DataResponse;
    const { lang, language, planName, labs, id, name } = accountData;
    return {
      accountId: id,
      lang: lang || language,
      planName,
      labs,
      name,
    };
  } catch (error) {
    throw new Error('Failed to fetch account data');
  }
}
