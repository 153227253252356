import { As, ChakraProps, Text as ChakraText } from '@chakra-ui/react';

interface TextProps extends ChakraProps {
  children: React.ReactNode;
  as?: As;
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'btnMenu'
    | 'p'
    | 'link'
    | 'helper';
}

const styles = {
  h1: {
    fontWeight: 'bold',
    fontSize: '48px',
  },
  h2: {
    fontWeight: 'bold',
    fontSize: '36px',
  },
  h3: {
    fontWeight: 'semibold',
    fontSize: '30px',
  },
  h4: {
    fontWeight: 'semibold',
    fontSize: '24px',
  },
  h5: {
    fontWeight: 'semibold',
    fontSize: '20px',
  },
  btnMenu: {
    fontWeight: 'semibold',
    fontSize: '18px',
  },
  p: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  link: {
    fontWeight: 'normal',
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#3B86FF',
  },
  helper: {
    fontWeight: 'normal',
    fontSize: '12px',
  },
};

export const Text = ({ children, as, variant, ...props }: TextProps) => {
  return (
    <ChakraText as={as} {...styles[variant]} {...props}>
      {children}
    </ChakraText>
  );
};
