export function inyectScriptElementFromString(str: string, id?: string) {
  const node = document
    .createRange()
    .createContextualFragment(str) as unknown as HTMLDivElement;

  if (id) {
    node.id = id;
  }
  document.body.appendChild(node);
}

export function inyectScriptIfDoesntExist(str: string, id: string) {
  if (!document.getElementById(id)) {
    inyectScriptElementFromString(str, id);
  }
}
