import { useEffect, useState } from 'react';
import { Center } from '@chakra-ui/react';

import { bootstrapAppData } from '.';

import {
  conversationActions,
  ConversationState,
} from '@/store/slices/conversation/conversation.slice';
import { useAppDispatch } from '@/hooks/redux';
import { Text } from '@/ui/Text/Text.component';
import { CLIENGO_WIDGET_SCRIPT } from '@/constants/environment';
import { inyectScriptIfDoesntExist } from '@/utils/inyectDomScript';

interface BootstrapDataProviderProps {
  view: React.ReactNode;
  useCliengoWidget?: boolean;
}

export const BootsrapDataProvider = ({
  view,
  useCliengoWidget = true,
}: BootstrapDataProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    bootstrapAppData()
      .then((data) => {
        dispatch(
          conversationActions.setInitialState(data as ConversationState)
        );
        if (useCliengoWidget) {
          inyectScriptIfDoesntExist(
            CLIENGO_WIDGET_SCRIPT,
            'clieng-widget-script'
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (error) {
    return (
      <Center minH="100vh">
        <Text variant="p">
          Error, no parece que tengas permisos. Estas logueado en el crm?
        </Text>
      </Center>
    );
  }

  return <>{loading ? <div>Loading...</div> : view}</>;
};
