import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { BootsrapDataProvider } from './bootstrap/BootstrapDataProvider';

const GeniaView = lazy(() => import('./views/Genia.view'));
const FeaturesView = lazy(() => import('./views/Features.view'));
const MainView = lazy(() => import('./views/Main.view'));

export const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div>...</div>}>
                <BootsrapDataProvider view={<MainView />} />
              </Suspense>
            }
          />

          <Route
            path="/cliengoia"
            element={
              <Suspense fallback={<div>...</div>}>
                <BootsrapDataProvider
                  view={<GeniaView />}
                  useCliengoWidget={false}
                />
              </Suspense>
            }
          />
          <Route
            path="/features"
            element={
              <Suspense fallback="...">
                <BootsrapDataProvider view={<FeaturesView />} />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <div>
                <p>404</p>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};
