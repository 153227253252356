import { createSlice } from '@reduxjs/toolkit';

import * as actions from './actions';

import { AppAccountData } from '@/bootstrap/adapters/accounDataAdapter';
import { AppChatbotConfigData } from '@/bootstrap/adapters/chatbotConfigAdapter';
import { AppUserData } from '@/bootstrap/adapters/userDataAdapter';
import { FeatureData } from '@/bootstrap/adapters/featuresDataAdapter';

export interface ConversationState {
  user: AppUserData | null;
  account: AppAccountData | null;
  websites: JsonObject[] | null;
  chatbotConfig?: AppChatbotConfigData | null;
  features: FeatureData | null;
}

const initialState: ConversationState = {
  user: null,
  account: null,
  websites: null,
  chatbotConfig: null,
  features: null,
};

export const layoutSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    ...actions,
  },
});

export const conversationActions = layoutSlice.actions;

export default layoutSlice.reducer;
