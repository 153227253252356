import { fetch } from '@/utils/fetch';

interface UserData {
  companyId: string;
  id: string;
  isSuperUser: boolean;
  labs: {
    [key: string]: string;
  };
}

interface UserDataResponse {
  data: UserData;
}

export interface AppUserData {
  companyId: UserData['companyId'];
  userId: UserData['id'];
  isSuperUser: UserData['isSuperUser'];
  labs: UserData['labs'];
}

export async function userDataAdapter() {
  try {
    const { data: userData } = (await fetch(
      '/users/me'
    )) as unknown as UserDataResponse;
    const { companyId, id, isSuperUser, labs } = userData;
    return {
      companyId: companyId,
      isSuperUser: isSuperUser,
      userId: id,
      labs: labs,
    };
  } catch (error) {
    throw new Error('Failed to fetch user data');
  }
}
