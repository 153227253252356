import { PayloadAction } from '@reduxjs/toolkit';

import { ConversationState } from '../conversation.slice';

export const setInitialState = (
  state: ConversationState,
  action: PayloadAction<ConversationState>
) => {
  Object.assign(state, action.payload);
};
